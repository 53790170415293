import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import LoginForm from './components/Auth/LoginForm';
import ConfirmForm from './components/Auth/ConfirmForm';
import PrivateRoute from './components/Route/PrivateRoute';
import Dashboard from './components/Dashboard/Dashboard';
import NotFound from './components/Layout/NotFound';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <PrivateRoute exact path="/">
            <Dashboard />
          </PrivateRoute>
          <Route path="/login">
            <LoginForm />
          </Route>
          <Route path="/confirm">
            <ConfirmForm />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
