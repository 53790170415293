import React from 'react';
import { Heading, GridItem, Text } from '@chakra-ui/react';

import { useAuth } from '../../hooks/useAuth';

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <GridItem
      colStart={[1, null, null, 2, null, null]}
      colSpan={[3, null, null, 1, null, null]}
      p={6}
    >
      <Heading as="h1" mb={6}>
        Access Custom Mobile Targeting
      </Heading>
      <Text fontSize="lg">Thanks for your interest in our Custom Mobile Targeting, {user.email}!<br></br><br></br>You should find it easy to use the Custom Mobile Targeting dashboard once you get started. There are two filters, one for mobileOS and one for Apps. You can select apps based upon the type of user you are looking to target and then select the platform. As a reminder, there is no PII attached to any of this information. If you would like assistance, please take the time to schedule a demo for some one on one guidance. You can schedule a demo by following the link at the end of the next paragraph.<br></br><br></br>Each day, ~4.4 million data points are updated and displayed. The information as presented is meaningless for targeting purposes. If you would like know how to use Custom Mobile Targeting, please take a moment to schedule a demo by following the highlighted text to the Appfluencer.com website</Text>
      <Text
        fontSize="lg"
        type="button"
        style={{ color: 'blue' }}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "https://appfluencer.com";
          }}>
        Homepage and Schedule a Demo.
      </Text>
      <br></br>

      <Text fontSize="lg">
      If you have questions, please send an email to: support@appfluencer.com
      </Text>
      <br></br>
      <Text
        fontSize="lg"
        type="button"
        style={{ color: 'blue' }}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "https://appfluencer.com/targeting";
        }}>
        Navigate to the CUSTOM MOBILE TARGETING DASHBOARD
      </Text>


    </GridItem>
  );
};

export default Dashboard;
